import { DependencyList, useEffect } from 'react'

export default function useMouseWheel(
  func: (event: WheelEvent) => void,
  listeners?: DependencyList,
) {
  useEffect(() => {
    window.addEventListener('wheel', func, { passive: false })

    return () => {
      window.removeEventListener('wheel', func)
    }
  }, [func, ...(listeners || [])])
}
