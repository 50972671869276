'use client'

import { PortableTextMarkDefinition } from '@portabletext/types'
import BlockContent from '@sanity/block-content-to-react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'

import Button from '@/components/Button'
import { List, ListItem } from '@/components/List'

import useAnimationFrame from '@/hooks/useAnimationFrame'
import useInIframe from '@/hooks/useInIframe'
import useMouseWheel from '@/hooks/useMouseWheel'
import useResize from '@/hooks/useResize'

import { dataset, getImageUrl, projectId } from '@/api/sanity'
import { ImageFields } from '../../../types'

import blockContentSerializers from '@/helpers/block-content-serializers'
import { scrollLeft } from '@/helpers/scrollTo'
import { randomFromArray } from '@/helpers/utils'

import { Language } from '@/lib/translations'

import BEMHelper from '@/helpers/bem'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

type talkingCardTranslations = {
  drawACard: string
  readMore: string
  print: string
  inCollaborationWith: string
  explainAndElaborate: string
  agreeDisagreeDiscuss: string
  conversationCard: string
  cardsPerPage: string
  perPage: string
  colors: string
  bw: string
  doubleSided: string
  drawCard: string
  printInfo: string
  singleSided: string
  start: string
  languageTitle: string
}

type Block = {
  children: {
    _type: string
    marks: string[]
    text: string
    _key: string
  }[]
  _type: string
  style: string
  _key: string
  markDefs: PortableTextMarkDefinition
}

interface Props {
  title: string
  collaberator?: { title: string; href: string }
  slug: string
  cards: Array<{
    _id: string
    _type: string
    type: string
    title: string | Block
    richText: Block
    textOrImage: string
    image?: string
  }>
  instructions: {
    title: string
    bullets: string[]
    details: string
    text: Block | string
  }
  language?: Language
  talkingCardTranslations?: talkingCardTranslations
  backLink?: string
  logo?: ImageFields // TODO: Image type
}

export default function TalkingCards({
  title,
  cards,
  collaberator,
  instructions,
  slug,
  talkingCardTranslations,
  backLink,
  logo,
}: Props) {
  const router = useRouter()
  const lang = talkingCardTranslations?.languageTitle || 'Norsk'
  const activeHistory = useRef<string[]>([])
  const scroll = useRef<number>(0)
  const compare = useRef<string>()
  const timer = useRef<NodeJS.Timeout | null>(null)
  const mainRef = useRef<HTMLElement | null>(null)
  const svgRef = useRef<SVGSVGElement | null>(null)
  const pathRef = useRef<SVGPathElement | null>(null)
  const wrapperRef = useRef<HTMLUListElement | null>(null)
  const pathLength = useRef(0)
  const [entering, setEntering] = useState(false)
  const [showInstructions, setShowInstructions] = useState(true)
  const [moreInstructions, setMoreInstructions] = useState(false)
  const [focus, setFocus] = useState<string | null>(null)
  const [active, setActive] = useState<string | null>(null)
  const {
    agreeDisagreeDiscuss,
    explainAndElaborate,
    drawACard,
    inCollaborationWith,
    print,
    readMore,
  } = talkingCardTranslations || {}

  const startLabel = talkingCardTranslations?.start || 'Start'

  const isIframe = useInIframe()

  const TASKS = {
    default: explainAndElaborate,
    claim: agreeDisagreeDiscuss,
    blank: '',
  }

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    if (entering) {
      timer.current = setTimeout(() => {
        setEntering(false)
      }, 1600)
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [entering])

  const handleUpdate = () => {
    // TODO: Could store this once, maybe...
    const elements = document.querySelectorAll('.' + bem('card').className)
    const positions: (number | HTMLElement)[][] = []

    elements.forEach((element: HTMLElement) => {
      const length = pathLength.current
      const elementRect = element.getBoundingClientRect()
      const center = elementRect.left + elementRect.width / 2
      const path = pathRef.current

      if (!path) {
        return
      }
      const pathRect = path.getBoundingClientRect()
      const pathLeft = pathRect.left
      const pathWidth = pathRect.width
      const percentage = Math.min(100, Math.max(0, ((center - pathLeft) * 100) / pathWidth))
      const point = (percentage * length) / 100

      const zIndex = 200 - Math.floor(Math.abs(percentage - 50) * 2)

      positions.push([percentage, element])

      const { x, y } = path.getPointAtLength(point)
      const { x: x2, y: y2 } = path.getPointAtLength(point + Math.PI)
      const deg = Math.atan2(y - y2, x - x2) * (180 / Math.PI)
      const angle = Math.floor((deg + 180) * 100) / 100
      const rotation = angle > 180 ? angle - 360 : angle
      const scale = Math.min(1, Math.ceil((1 - Math.abs(percentage - 50) / 150) * 100) / 100)

      const top = Math.min(y, 200)

      element.style.transform = `scale(${scale}) translateY(${top}px) rotate(${rotation}deg)`
      element.style.setProperty('--z-index', `${zIndex}`)
    })

    if (!positions.length) {
      return
    }

    const center = positions.reduce((prev, curr) =>
      typeof curr[0] === 'number' &&
        typeof prev[0] === 'number' &&
        Math.abs(curr[0] - 50) < Math.abs(prev[0] - 50)
        ? curr
        : prev,
    )

    if (center?.[1] instanceof HTMLElement) {
      const id = center[1].getAttribute('id')
      setFocus(id || null)
    }
  }

  useAnimationFrame(() => {
    const scrollLeft = wrapperRef.current ? wrapperRef.current.scrollLeft : 0
    const compareStr = `${scrollLeft}${window.innerWidth}${window.innerHeight}${focus}${active}`
    if (compare.current === compareStr) {
      return
    }

    compare.current = compareStr

    handleUpdate()
  }, !showInstructions)

  useResize(() => {
    setActive(null)

    if (pathRef.current) {
      pathLength.current = pathRef.current.getTotalLength()
      setTimeout(handleUpdate, 10)
    }
  }, [wrapperRef.current, pathRef.current])

  const updateScroll = useCallback((value: number) => {
    if (wrapperRef.current) {
      const scrollPos = wrapperRef.current.scrollLeft
      wrapperRef.current.scrollLeft = scrollPos + value
    }
  }, [])

  useMouseWheel(
    (event) => {
      if (Math.abs(event.deltaY) > Math.abs(event.deltaX) && !active && !showInstructions) {
        event.preventDefault()
        updateScroll(event.deltaY)
      }
    },
    [active, showInstructions],
  )

  const toggleActive =
    (id: string, force = false) =>
      () => {
        if (active && !force) {
          setActive(null)

          scrollLeft({
            element: wrapperRef.current,
            to: scroll.current,
            duration: 400,
            easing: force ? 'easeOutQuart' : undefined,
          }).then(handleUpdate)
        } else {
          if (wrapperRef.current) {
            scroll.current = wrapperRef.current.scrollLeft
          }

          const screenWidth = window.innerWidth
          const spacing = screenWidth > 760 ? 66 : 34.6 // TODO: Find a way to calculate this instead...
          const index = cards.findIndex((card) => card._id === id)

          if (force && active) {
            const duration = screenWidth > 760 ? 400 : 300

            scrollLeft({
              element: wrapperRef.current,
              to: index * spacing,
              duration,
              easing: force ? 'easeOutQuart' : undefined,
            }).then(handleUpdate)
          } else {
            if (wrapperRef.current) {
              wrapperRef.current.scrollLeft = index * spacing
            }
          }

          setActive(id)
        }

        // Not pretty, but...
        setTimeout(handleUpdate, 10)
        setTimeout(handleUpdate, 20)
        setTimeout(handleUpdate, 30)
        setTimeout(handleUpdate, 100)
        setTimeout(handleUpdate, 200)
        setTimeout(handleUpdate, 300)
        setTimeout(handleUpdate, 400)
        setTimeout(handleUpdate, 500)
      }

  const randomize = () => {
    // Reset history when they've been through all cards
    if (activeHistory.current.length === cards.length) {
      activeHistory.current = []
    }

    const card = randomFromArray(cards.filter((card) => !activeHistory.current.includes(card._id)))

    if (card._id) {
      activeHistory.current.push(card._id)
      toggleActive(card._id, true)()
    }
  }

  const start = () => {
    if (mainRef.current) {
      mainRef.current.scrollTop = 0
    }
    setShowInstructions(false)
    setEntering(true)
  }

  const toggleMore = (value: boolean) => () => {
    if (mainRef.current) {
      mainRef.current.scrollTop = 0
    }
    0
    setMoreInstructions(value)
  }

  const logoUrl = logo ? getImageUrl(logo).url() : null
  const logoAspect = logo?.dimensions?.aspectRatio || 1
  const width = 300 * logoAspect

  const closeLink = backLink || router?.query?.referer?.toString() || '/'

  return (
    <main {...bem('', { active, instructions: showInstructions, iframe: isIframe })} ref={mainRef}>
      <nav {...bem('controls')}>
        <Button
          {...bem('control', 'print')}
          label={print}
          xsmall
          href={`/samtalekort/${slug}/pdf`}
        />

        <Button
          {...bem('control', 'close')}
          label="Lukk"
          href={closeLink}
          circle
          primary
          target="_top"
          icon="close"
        />
      </nav>

      <div {...bem('wrapper')}>
        <header {...bem('header')}>
          {logoUrl && !isIframe ? (
            <Image src={logoUrl} width={width} height={300} alt="" {...bem('logo')} />
          ) : (
            <Image
              src="/icons/diskusjon.png"
              alt=""
              {...bem('illustration')}
              height={135}
              width={135}
              style={{ width: '100%', height: 'auto' }}
            />
          )}
          <h1 {...bem('title')}>{title}</h1>
        </header>

        {showInstructions && (
          <article {...bem('instructions', { more: moreInstructions, default: !moreInstructions })}>
            {moreInstructions ? (
              <>
                <Button
                  {...bem('control', 'back')}
                  label="Tilbake"
                  circle
                  primary
                  icon="chevron"
                  onClick={toggleMore(false)}
                />
                <div {...bem('instructions-page')}>
                  <BlockContent
                    renderContainerOnSingleChild
                    className={bem('text').className}
                    blocks={instructions.text}
                    serializers={blockContentSerializers}
                    dataset={dataset}
                    projectId={projectId}
                  />
                  <div {...bem('actions')}>
                    <Button
                      {...bem('action', 'primary')}
                      label={startLabel}
                      primary
                      large
                      full
                      onClick={start}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div {...bem('instructions-page')}>
                <h2 {...bem('title', 'small')}>{instructions.title}</h2>
                <List {...bem('numbers')} type="ol">
                  {instructions.bullets.map((item) => (
                    <ListItem key={item}>{item}</ListItem>
                  ))}
                </List>
                <p {...bem('details')}>{instructions.details}</p>

                <div {...bem('actions')}>
                  <Button
                    {...bem('action', 'primary')}
                    label={startLabel}
                    primary
                    large
                    full
                    onClick={start}
                  />
                  <Button
                    {...bem('action', 'muted')}
                    label={readMore}
                    large
                    full
                    onClick={toggleMore(true)}
                  />
                </div>
              </div>
            )}
          </article>
        )}

        {!showInstructions && (
          <ul {...bem('cards', { active, entering })} ref={wrapperRef}>
            {cards.map((card) => {
              return (
                <li
                  key={card._id}
                  id={card._id}
                  {...bem('card', {
                    focus: focus === card._id,
                    active: active === card._id,
                    claim:
                      card.type === 'claim' &&
                      !(lang === 'Russisk' || lang === 'Spansk' || lang === 'Tysk'),
                    ['claim-alt']:
                      card.type === 'claim' &&
                      (lang === 'Russisk' || lang === 'Spansk' || lang === 'Tysk'),
                  })}
                  onClick={toggleActive(card._id)}
                >
                  {card.textOrImage === 'image' && card.image ? (
                    <Image
                      src={getImageUrl(card.image).url()}
                      alt=""
                      {...bem('image')}
                      width={300}
                      height={300}
                    />
                  ) : (
                    <>
                      {card?.title && card?.title[0]._type === 'block' && (
                        <div {...bem('sub-title')}>
                          <BlockContent blocks={card.title} serializers={blockContentSerializers} />
                        </div>
                      )}
                      {card?.richText && (
                        <div {...bem('sub-title')}>
                          <BlockContent
                            blocks={card.richText}
                            serializers={blockContentSerializers}
                          />
                        </div>
                      )}
                      {card?.type && <p {...bem('task')}>{TASKS[card.type] ?? TASKS.default}</p>}
                    </>
                  )}
                </li>
              )
            })}
          </ul>
        )}
      </div>

      {!showInstructions && (
        <nav {...bem('controls', 'footer')}>
          <Button {...bem('action', 'primary')} label={drawACard} primary onClick={randomize} />

          {collaberator && (
            <p {...bem('disclaimer')}>
              {inCollaborationWith}{' '}
              <a href={collaberator.href} target="_blank" rel="noopener noreferrer">
                {collaberator.title}
              </a>
              .
            </p>
          )}
        </nav>
      )}

      <svg viewBox="0 0 1680 1531" {...bem('path', { active })} ref={svgRef}>
        <path
          ref={pathRef}
          d="M-1192 996C-1192 446.477 -282.243 1 840 1C1962.24 1 2872 446.477 2872 996"
        />
      </svg>
    </main>
  )
}

interface EntryProps {
  title: string
  slug: string
  topicTheme?: {
    background: string
    promoted: string
  }
  language?: Language
  talkingCardTranslations?: talkingCardTranslations
}

export function TalkingEntry({ title, slug, topicTheme, talkingCardTranslations }: EntryProps) {
  const { conversationCard } = talkingCardTranslations || {}
  const router = useRouter()

  return (
    <Link
      href={{ pathname: '/samtalekort/' + slug, query: { referer: router.asPath } }}
      {...bem('entry')}
      style={
        {
          '--color-background': topicTheme?.background,
          '--color-promoted': topicTheme?.promoted,
        } as CSSProperties
      }
    >
      {/* Needs fix */}
      <Image
        src="/icons/samtalekort.png"
        alt=""
        {...bem('illustration', 'entry')}
        width={150}
        height={150}
      />

      <span {...bem('tag')}>{conversationCard}</span>
      <h3 {...bem('entry-title')}>{title}</h3>
    </Link>
  )
}

// <Image src={`/icons/samtalekort.png`} alt="" width={600} height={600} />
