import { useEffect, useState } from 'react'

export default function useInIframe() {
  const [inIframe, setInIframe] = useState(false)

  useEffect(() => {
    try {
      setInIframe(window.self !== window.top)
    } catch (e) {
      // noop
    }
  }, [])

  return inIframe
}
